<template>
  <div></div>
</template>

<script>
export default {
  name: "RedirectPage",
  data() {
    return {};
  },

  created() {
    this.$router.go(-2);
  },
  computed: {},
  methods: {}
};
</script>

<style scoped></style>
